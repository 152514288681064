import React from "react";
import { IconProps } from "./interfaces/IconProps";
const VerticalEllipsisIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <>
      <svg
        width="3"
        height="17"
        viewBox="0 0 3 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM3 8.5C3 9.32843 2.32843 10 1.5 10C0.671573 10 0 9.32843 0 8.5C0 7.67157 0.671573 7 1.5 7C2.32843 7 3 7.67157 3 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17Z"
          fill="#4A4A4A"
        />
      </svg>
    </>
  );
};
export default VerticalEllipsisIcon;
