export const MODALS = {
  NONE: null,
  VICTORY: "VICTORY",
  SCORE_BETTER: "SCORE_BETTER",
  EXIT: "EXIT",
  OFFLINE: "OFFLINE",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  USER_OFFLINE_REPLAY: "USER_OFFLINE_REPLAY",
  USER_OFFLINE_END: "USER_OFFLINE_END",
  UNEXPECTED_ERROR_GAME_START: "UNEXPECTED_ERROR_GAME_START",
  UNEXPECTED_ERROR_GAME_END: "UNEXPECTED_ERROR_GAME_END",
};
type ParamKeys = "gameId";
export type UrlParams = { [key in ParamKeys]: string };
// Define constants for the app-to-web functions
const WebToApp = {
  CLOSE_WEBVIEW: "closeWebview",
  ANALYTICS_LISTENER: "analyticsListener",
  //implemented
  LOAD_RD_AD: "loadRDAd",
  //implemented
  SHOW_RD_AD: "showRDAd",
  IS_RD_AD_LOADED: "isRDAdLoaded",
  LOAD_INS_AD: "loadINSAd",
  SHOW_INS_AD: "showINSAd",
  IS_INS_AD_LOADED: "isINSAdLoaded",
  LOAD_BN_AD: "loadBNAd",
  DESTROY_BN: "destroyBN",
  CALL_FUNCTION_IN_ANDROID: "callFunctionInAndroid",
};
export const EventTypes = {
  card_click: "card_click",
  game_open: "game_open",
  game_load_success: "game_load_success",
  game_start_success: "game_start_success",
  game_pause: "game_pause",
  game_exit: "game_exit",
  game_end: "game_end",
  bottom_sheet_viewed: "bottom_sheet_viewed",
  bottom_sheet_action: "bottom_sheet_action",
  ad_load: "ad_load",
  ad_load_success: "ad_load_success",
  ad_show: "ad_show",
  api_status: "api_status",
  first_load_success: "first_load_success",
  webview_close: "webview_close",
  modal_viewed: "modal_viewed",
  modal_action: "modal_action",
};
export const ReferrerFlowState = {
  replay: "Replay",
  homepage: "Homepage",
  exit: "Exit",
  continue: "Continue",
};
export const PopupActions = {
  REPLAY: "Replay",
  HOME: "Home",
  DISMISS: "Dismiss",
  ANDROID_BACK: "Android Back",
  EXIT: "Exit",
  CONTINUE: "Continue",
  RETRY: "Retry",
};
export const EventSource = {
  ANDROID_BACK: "Android_Back",
  HOME_CLICK: "Home_Click",
  AD_LOAD: "Ad_Load",
  AD_SHOW: "Ad_Show",
  HOME_LOAD: "Home_Load",
  GAME_OPEN: "Game_Open",
  GAME_END: "Game_End",
  GAME_REPLAY: "Game_Replay",
};
export const PopupType = {
  REWARD: "Reward",
  EXIT: "Exit",
  OFFLINE: "Offline",
  UNEXPECTED_ERROR: "Unexpected_Error",
};
// Define constants for the app-to-web functions
const AppToWeb = {
  ON_RD_AD_DISPLAYED: "onRDAdDisplayed",
  ON_RD_AD_DISPLAY_FAILED: "onRDAdDisplayFailed",
  ON_RD_AD_DISMISSED: "onRDAdDismissed",
  ON_RD_AD_LOAD_SUCCEEDED: "onRDAdLoadSucceeded",
  ON_RD_AD_LOAD_FAILED: "onRDAdLoadFailed",
  ON_DISTRIBUTE_REWARDS: "onDistributeRewards",
  ON_INS_AD_DISPLAYED: "onINSAdDisplayed",
  ON_INS_AD_DISPLAY_FAILED: "onINSAdDisplayFailed",
  ON_INS_AD_DISMISSED: "onINSAdDismissed",
  ON_INS_AD_LOAD_SUCCEEDED: "onINSAdLoadSucceeded",
  ON_INS_AD_LOAD_FAILED: "onINSAdLoadFailed",
  ON_BN_AD_EXPANDED: "onBNAdExpanded",
  ON_BN_AD_COLLAPSED: "onBNAdCollapsed",
  ON_BN_AD_CLICKED: "onBNAdClicked",
  ON_BN_AD_LOAD_SUCCEEDED: "onBNAdLoadSucceeded",
  ON_BN_AD_LOAD_FAILED: "onBNAdLoadFailed",
  INVALID_BN_AD_POSITION: "invalidBNAdPosition",
  AD_SDK_NOT_INITIALISED: "adSdkNotInitialised",
  ANDROID_BACK: "androidBack",
  USER_LEFT_APPLICATION: "userLeftApplication",
  USER_RESUME_APPLICATION: "userResumeApplication",
  TEST_FUNCTION_CALLED_FROM_ANDROID: "testfunctioncalledfromandroid",
};

export const HOME_PAGE_PARAMS = {
  bundleId: "bundleId",
  userId: "userId",
  thirdPartyId: "thirdPartyId",
  ipAddress: "ipAddress",
  accountId: "accountId",
  webViewSessionId: "webviewSessionId",
};

export { WebToApp, AppToWeb };
