const OfflineIcon = () => {
  return (
    <svg
      width="175"
      height="176"
      viewBox="0 0 175 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg
        width="175"
        height="176"
        viewBox="0 0 175 176"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M86.8538 175.272C133.717 175.272 171.708 137.437 171.708 90.7658C171.708 44.0944 133.717 6.2597 86.8538 6.2597C39.9903 6.2597 2 44.0944 2 90.7658C2 137.437 39.9903 175.272 86.8538 175.272Z"
          fill="url(#paint0_linear_357_10962)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.636 52.9995C128.507 50.8936 129.127 46.8596 127.021 43.9891C124.915 41.1187 120.881 40.4989 118.011 42.6047C115.14 44.7106 114.52 48.7447 116.626 51.6151C117.925 53.3855 119.957 54.2997 121.998 54.2464C122.144 54.2303 122.295 54.221 122.448 54.2188C123.564 54.1112 124.667 53.7109 125.636 52.9995ZM96.194 54.7397C102.559 56.1568 108.345 57.0416 114.16 56.2517C113.604 55.7461 113.09 55.1777 112.628 54.5482C108.902 49.4697 109.999 42.3325 115.078 38.6068C120.156 34.881 127.293 35.9776 131.019 41.0561C132.957 43.6979 133.59 46.8968 133.037 49.8828C137.635 47.282 141.729 43.9961 144.91 39.7946C145.736 38.7028 147.285 38.5235 148.369 39.3942C149.453 40.2649 149.662 41.8559 148.835 42.9477C143.093 50.5339 135.072 54.9783 126.807 58.0623C125.634 58.6336 124.395 58.9874 123.143 59.132C113.782 62.526 104.124 61.7075 95.101 59.6984C93.7761 59.4034 92.9468 58.0542 93.2486 56.6849C93.5504 55.3156 94.8691 54.4447 96.194 54.7397Z"
          fill="black"
        />
        <path
          d="M107.549 54.5987C107.549 38.2733 94.315 25.039 77.9896 25.039H49.8209V84.1585H77.9896C94.315 84.1585 107.549 70.9241 107.549 54.5987Z"
          fill="#FFA984"
        />
        <path
          d="M74.4483 73.4164C70.2602 81.5368 63.2495 86.8539 55.3125 86.8539H47.6456C34.8112 86.8539 24.4037 72.9483 24.4037 55.8004C24.4037 38.6524 34.8112 24.7517 47.6456 24.7469H55.3366C63.2737 24.7469 70.294 30.0543 74.4724 38.1844C77.1658 43.6645 78.5663 49.6894 78.5663 55.7956C78.5663 61.9018 77.1658 67.9266 74.4724 73.4067L74.4483 73.4164Z"
          fill="#011625"
        />
        <path
          d="M66.6946 73.4165C62.5162 81.5224 55.52 86.8347 47.5974 86.854C34.7726 86.854 24.3796 72.9388 24.3796 55.8005C24.3796 38.6622 34.7726 24.7663 47.5974 24.747C55.52 24.747 62.5162 30.0544 66.6946 38.1845C69.3912 43.6637 70.7935 49.6889 70.7935 55.7957C70.7935 61.9024 69.3912 67.9276 66.6946 73.4068V73.4165Z"
          fill="#F3CFC3"
        />
        <path
          d="M48.2924 61.526C47.1611 59.4952 45.2668 58.1644 43.1217 58.1595C39.6492 58.1595 36.8352 61.6456 36.8352 65.9392C36.8352 70.2327 39.6492 73.7139 43.1217 73.7188C45.2668 73.7188 47.1611 72.3891 48.2924 70.3523C49.0226 68.9797 49.4023 67.4702 49.4023 65.9404C49.4023 64.4105 49.0226 62.901 48.2924 61.5284V61.526Z"
          fill="white"
        />
        <path
          d="M48.2924 40.5808C47.1611 38.5501 45.2668 37.2192 43.1217 37.2144C39.6492 37.2144 36.8352 40.7005 36.8352 44.994C36.8352 49.2875 39.6492 52.7688 43.1217 52.7736C45.2668 52.7736 47.1611 51.444 48.2924 49.4072C49.0226 48.0345 49.4023 46.5251 49.4023 44.9952C49.4023 43.4653 49.0226 41.9559 48.2924 40.5832V40.5808Z"
          fill="white"
        />
        <path
          d="M22 66.4049C22 69.2956 24.3434 71.639 27.2341 71.639H42.1702C45.2432 71.639 47.7344 69.1479 47.7344 66.0749C47.7344 63.0018 45.2432 60.5107 42.1702 60.5107H27.2341C24.3434 60.5107 22 62.8541 22 65.7448V66.4049Z"
          fill="#F86A1B"
        />
        <path
          d="M22 44.8614C22 47.7423 24.3355 50.0778 27.2164 50.0778H42.5179C45.3989 50.0778 47.7344 47.7423 47.7344 44.8614C47.7344 41.9804 45.3989 39.645 42.5179 39.645H27.2164C24.3355 39.645 22 41.9804 22 44.8614Z"
          fill="#F86A1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.3239 82.1962H78.5721V84.2828H64.3239V82.1962Z"
          fill="#011625"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.6393 127.172C45.6685 119.949 59.2957 118.574 72.343 119.006C74.2073 119.068 75.6685 120.629 75.6068 122.494C75.545 124.358 73.9836 125.819 72.1193 125.757C59.5975 125.343 47.513 126.721 37.1167 132.964C35.5176 133.924 33.4427 133.406 32.4824 131.807C31.5222 130.208 32.0401 128.133 33.6393 127.172Z"
          fill="#011625"
        />
        <path
          d="M62.603 122.412C62.603 106.279 75.6817 93.2001 91.815 93.2001H120.331V151.624H91.815C75.6817 151.624 62.603 138.545 62.603 122.412Z"
          fill="#FFA984"
        />
        <path
          d="M91.0926 141.668C95.2855 149.788 102.296 155.105 110.233 155.105H117.924C130.759 155.105 141.166 141.2 141.166 124.052C141.166 106.904 130.759 93.0032 117.924 92.9984H110.233C102.296 92.9984 95.2759 98.3059 91.0926 106.436C88.3513 111.901 86.9473 117.938 86.9962 124.052C86.9473 130.165 88.3513 136.203 91.0926 141.668Z"
          fill="#011625"
        />
        <path
          d="M98.8458 141.668C103.029 149.774 110.02 155.086 117.948 155.105C130.773 155.105 141.166 141.19 141.166 124.052C141.166 106.914 130.773 93.0177 117.948 93.0032C110.02 93.0032 103.029 98.3107 98.8458 106.436C96.1045 111.901 94.7005 117.938 94.7494 124.052C94.7005 130.165 96.1045 136.203 98.8458 141.668Z"
          fill="#F3CFC3"
        />
        <path
          d="M120.05 128.176C123.189 128.176 125.735 131.525 125.735 135.656C125.735 139.788 123.189 143.137 120.05 143.137C116.91 143.137 114.365 139.788 114.365 135.656C114.365 131.525 116.91 128.176 120.05 128.176Z"
          fill="white"
        />
        <path
          d="M120.05 104.239C123.189 104.239 125.735 107.588 125.735 111.719C125.735 115.85 123.189 119.2 120.05 119.2C116.91 119.2 114.365 115.85 114.365 111.719C114.365 107.588 116.91 104.239 120.05 104.239Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.1017 149.688H101.1V151.774H89.1017V149.688Z"
          fill="#011625"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148.088 14.9558C148.996 11.2735 148.937 7.72738 149.045 3.9923C149.067 3.26286 148.492 2.65642 147.763 2.63445C147.033 2.61248 146.424 3.18814 146.402 3.91758C146.299 7.45931 146.382 10.8296 145.52 14.3274C145.346 15.0349 145.78 15.7512 146.488 15.9225C147.197 16.0983 147.914 15.6633 148.088 14.9558Z"
          fill="#F86A1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.805 22.092C161.967 16.4762 167.773 9.25216 171.831 1.95777C172.185 1.32061 171.955 0.516438 171.317 0.164902C170.678 -0.186636 169.873 0.0418835 169.519 0.679043C165.595 7.73175 159.979 14.7186 154.021 20.1454C153.482 20.6376 153.445 21.4725 153.937 22.0086C154.429 22.5446 155.266 22.5842 155.805 22.092Z"
          fill="#F86A1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.366 28.2263C163.002 29.1535 168.811 27.5716 173.936 25.3349C174.604 25.0405 174.909 24.2628 174.617 23.5993C174.324 22.9313 173.544 22.6281 172.875 22.9181C168.208 24.957 162.93 26.4687 157.796 25.625C157.076 25.5063 156.395 25.9941 156.277 26.7103C156.158 27.4266 156.646 28.1077 157.366 28.2263Z"
          fill="#F86A1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.08089 162.441C2.88386 162.155 3.72885 161.774 4.38046 161.224C5.15436 160.57 5.4691 159.728 5.68046 158.841C5.95181 157.702 6.06023 156.489 6.38927 155.348C6.5111 154.925 6.74555 154.765 6.84616 154.694C7.10043 154.514 7.35746 154.466 7.59927 154.484C7.88585 154.505 8.27951 154.613 8.5384 155.094C8.57532 155.162 8.6233 155.267 8.6556 155.411C8.67914 155.516 8.69437 155.845 8.71929 155.981C8.78159 156.315 8.83373 156.649 8.88265 156.985C9.04555 158.103 9.13924 159.053 9.65379 160.08C10.352 161.475 11.0516 162.328 12.0004 162.706C12.9179 163.071 14.0148 163.002 15.4163 162.716C15.5497 162.684 15.6816 162.656 15.8122 162.633C16.4302 162.525 17.0209 162.931 17.1422 163.547C17.2636 164.162 16.8709 164.761 16.2585 164.896C16.1307 164.925 16.0047 164.951 15.8801 164.976C13.9862 165.445 11.7937 167.118 10.5195 168.583C10.1268 169.035 9.55181 170.298 8.96526 171.104C8.5324 171.698 8.04599 172.09 7.63758 172.228C7.36392 172.322 7.13319 172.307 6.9426 172.26C6.66571 172.192 6.4359 172.042 6.25962 171.803C6.16363 171.673 6.07454 171.498 6.03209 171.275C6.01178 171.167 6.00949 170.894 6.00995 170.77C5.89042 170.359 5.74413 169.957 5.63753 169.543C5.38325 168.553 4.88439 167.926 4.29185 167.098C3.73761 166.323 3.14232 165.836 2.26966 165.448C2.15613 165.42 1.24009 165.196 0.916594 165.067C0.444038 164.878 0.218824 164.562 0.137142 164.392C-0.00176367 164.103 -0.0160569 163.851 0.0116319 163.64C0.0527036 163.33 0.192072 163.064 0.439425 162.849C0.592637 162.716 0.821533 162.586 1.12796 162.523C1.3647 162.474 1.99274 162.445 2.08089 162.441ZM7.45483 160.828C7.49729 160.922 7.54252 161.017 7.59051 161.113C8.61315 163.156 9.7567 164.296 11.1467 164.849L11.1933 164.867C10.2634 165.558 9.42165 166.329 8.77835 167.069C8.51346 167.374 8.16275 168.006 7.78387 168.655C7.43961 167.536 6.8766 166.745 6.16869 165.755C5.62784 165 5.06114 164.431 4.36477 163.968C4.90516 163.691 5.42063 163.366 5.86965 162.986C6.61725 162.355 7.11149 161.623 7.45483 160.828Z"
          fill="#F86A1B"
        />
        <defs>
          <linearGradient
            id="paint0_linear_357_10962"
            x1="86.1997"
            y1="-21.2575"
            x2="87.9237"
            y2="275.515"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFF7F3" />
            <stop offset="1" stopColor="#FFFCE6" />
          </linearGradient>
        </defs>
      </svg>
    </svg>
  );
};

export default OfflineIcon;
