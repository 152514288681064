export enum Pages {
  HOME,
  OOPS,
  SETTINGS,
  FEEDBACK,
  TERMS_OF_SERVICE,
  GAME,
}

export enum GamePopups {
  NONE,
  VICTORY,
  SCORE_BETTER,
  OFFLINE,
  UNEXPECTED_ERROR,
  EXIT,
}
