import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { AdUnit } from "../types/AdUnit";

type AdUnitState = {
  adUnits: AdUnit[];
  getAdUnitByPlacement: (placement_name: string) => AdUnit | undefined;
  setAdUnits: (adUnits: AdUnit[]) => void;
};

const useAdUnitStore = create<AdUnitState>()(
  persist(
    (set, get) => ({
      adUnits: [],
      getAdUnitByPlacement: (placement_name) =>
        get().adUnits?.find(
          (adUnit) => adUnit.placementName === placement_name
        ),
      setAdUnits: (adUnits) => set({ adUnits }),
    }),
    {
      name: "adUnits-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useAdUnitStore;
