import React from "react";
import { IconProps } from "./interfaces/IconProps";
const BackIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <>
      <svg
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M18 7C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9V7ZM0.792892 8.70711C0.402369 8.31658 0.402369 7.68342 0.792892 7.29289L7.15685 0.928932C7.54738 0.538408 8.18054 0.538408 8.57107 0.928932C8.96159 1.31946 8.96159 1.95262 8.57107 2.34315L2.91421 8L8.57107 13.6569C8.96159 14.0474 8.96159 14.6805 8.57107 15.0711C8.18054 15.4616 7.54738 15.4616 7.15685 15.0711L0.792892 8.70711ZM18 9H1.5V7H18V9Z"
          fill="black"
        />
      </svg>
    </>
  );
};
export default BackIcon;
