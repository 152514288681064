// src/utils/webToAppCallers.js

import { Dispatch, SetStateAction } from "react";
import { EventTypes, WebToApp } from "../constants/Constants";
import { BannerAdConfig } from "../types/BannerAdConfig";
import { Android, EventInfo } from "../types/window";
import useSessionStore from "../store/useSessionStore";
import { AdFormat } from "../constants/AdConstants";

export const callFunctionInAndroid = (
  message: string,
  setTestOutput: Dispatch<SetStateAction<string>>,
) => {
  // console.log(`callFunctionInAndroid was clicked with message ${message}`);
  setTestOutput((state) => state + "callFunctionInAndroid was clicked\n");
  if (window.Android && window.Android.androidFunctionCalledFromWebView) {
    if (setTestOutput)
      setTestOutput(
        (state) =>
          state +
          "window.Android.androidFunctionCalledFromWebView(message) will be called\n" +
          `Parameters: message = ${message}\n`,
      );
    window.Android.androidFunctionCalledFromWebView(message);
  } else {
    if (setTestOutput)
      setTestOutput(
        (state) =>
          state +
          "Issue with window.Android.androidFunctionCalledFromWebView(message) function\n",
      );
  }
};

export const closeWebview = (
  setTestOutput: Dispatch<SetStateAction<string>>,
) => {
  // console.log("closeWebview was clicked");
  if (setTestOutput)
    setTestOutput((state) => state + "closeWebview() was clicked\n");
  if (window.Android && window.Android.closeWebview) {
    if (setTestOutput)
      setTestOutput(
        (state) => state + "window.Android.closeWebview() will be called\n",
      );
    window.Android.closeWebview();
  } else {
    if (setTestOutput)
      setTestOutput(
        (state) =>
          state + "Issue with window.Android.closeWebview() function\n",
      );
  }
};

export const analyticsListener = (
  jsonInput: EventInfo,
  setTestOutput: Dispatch<SetStateAction<string>>,
) => {
  // console.log("analyticsListener was clicked with input:", jsonInput);
  if (setTestOutput)
    setTestOutput((state) => state + `analyticsListener was clicked\n`);
  try {
    //const eventInfo = JSON.parse(jsonInput);
    if (window.Android && window.Android.analyticsListener) {
      if (setTestOutput)
        setTestOutput(
          (state) =>
            state +
            "window.Android.analyticsListener(eventInfo) will be called\n" +
            `Parameters: eventInfo = ${jsonInput}\n`,
        );
      // console.log(
      //   "sending analytics info to android with parameters event name:",
      //   jsonInput.eventType,
      //   " event props:",
      //   JSON.stringify(jsonInput.eventProperties)
      // );
      window.Android.analyticsListener(
        jsonInput.eventType,
        JSON.stringify(jsonInput.eventProperties),
      );
    } else {
      if (setTestOutput)
        setTestOutput(
          (state) =>
            state +
            "Issue with window.Android.analyticsListener(eventInfo) function\n",
        );
    }
  } catch (error) {
    if (setTestOutput) setTestOutput((state) => state + "Invalid JSON input\n");
  }
};
const callIsInsAdLoaded = (
  mediation: string,
  adUnit: string,
  placement: string,
) => {
  // console.log(`mediation is ${mediation}, ${adUnit}, ${placement}`);
  return window.Android.isINSAdLoaded(mediation, adUnit, placement);
};
export const loadBNAd = (
  mediation: string,
  bannerAdConfig: BannerAdConfig,
  placement: string,
  adUnit: string,
  setTestOutput: Dispatch<SetStateAction<string>>,
) => {
  console.log("loadBNAd was clicked with config:", bannerAdConfig);
  // setTestOutput(
  //   (state) =>
  //     state +
  //     `loadBNAd was clicked with config: ${JSON.stringify(bannerAdConfig)}\n`
  // );
  if (window.Android && window.Android.loadBNAd) {
    // setTestOutput(
    //   (state) =>
    //     state +
    //     "window.Android.loadBNAd(mediation, bannerAdConfig, placement, adUnit) will be called\n" +
    //     `Parameters: mediation = ${mediation}, bannerAdConfig = ${JSON.stringify(
    //       bannerAdConfig
    //     )}, placement = ${placement}, adUnit = ${adUnit}\n`
    // );
    const { gameSessionId } = useSessionStore.getState();
    const onBnAdLoadSuccessEvent: EventInfo = {
      eventType: EventTypes.ad_load,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: window.navigator.onLine ? "online" : "offline",
        ad_type: AdFormat.Banner,
        placement: placement,
        mediation: mediation,
        game_session_id: gameSessionId,
        ad_unit: adUnit,
      },
    };
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      onBnAdLoadSuccessEvent,
      null,
      undefined,
    );
    window.Android.loadBNAd(
      mediation,
      JSON.stringify(bannerAdConfig),
      placement,
      adUnit,
    );
  } else {
    // setTestOutput(
    //   (state) =>
    //     state +
    //     "Issue with window.Android.loadBNAd(mediation, bannerAdConfig, placement, adUnit) function\n"
    // );
  }
};

export const callWebToAppFunction = (
  functionName: string,
  mediation: string,
  adUnit: string,
  placement: string,
  jsonInput?: EventInfo,
  bannerAdConfig?: BannerAdConfig | null,
  setTestOutput?: Dispatch<SetStateAction<string>>,
) => {
  // console.log("setoutput function", setTestOutput);
  // console.log("test webtoapp", mediation, adUnit, placement);
  if (setTestOutput) setTestOutput("");
  if (window.Android && window.Android[functionName as keyof Android]) {
    switch (functionName) {
      case "closeWebview":
        closeWebview(setTestOutput!);
        break;
      case "analyticsListener":
        analyticsListener(jsonInput!, setTestOutput!);
        break;
      case "loadBNAd":
        // console.log("banner ad config", bannerAdConfig);
        loadBNAd(mediation, bannerAdConfig!, placement, adUnit, setTestOutput!);
        break;
      case WebToApp.IS_INS_AD_LOADED:
        return callIsInsAdLoaded(mediation, adUnit, placement);
      default:
        if (setTestOutput)
          setTestOutput(
            (state) => state + `window.Android.${functionName} was clicked\n`,
          );
        if (window.Android && window.Android[functionName as keyof Android]) {
          if (setTestOutput)
            setTestOutput(
              (state) =>
                state +
                `window.Android.${functionName}(mediation, adUnit, placement) will be called\n` +
                `Parameters: mediation = ${mediation}, adUnit = ${adUnit}, placement = ${placement}\n`,
            );
          window.Android[functionName as keyof Android](
            mediation,
            adUnit,
            placement,
          );
        } else {
          if (setTestOutput)
            setTestOutput(
              (state) =>
                state +
                `Issue with window.Android.${functionName}(mediation, adUnit, placement) function\n`,
            );
        }
        break;
    }
  }
};
