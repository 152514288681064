import { MutableRefObject, useEffect } from "react";
import {
  EventTypes,
  PopupActions,
  PopupType,
  WebToApp,
} from "../../../constants/Constants";
import { EventInfo } from "../../../types/window";
import { callWebToAppFunction } from "../../../utils/androidCallers";
import { OfflineIcon } from "../../icons";
import Modal from "../Modal";
import { Game } from "../../../types/Game";
type OfferModalProps = {
  open: boolean;
  imageUrl: string;
  onCloseClickedHandler: () => void;
};
const OfferModal = ({
  open,
  imageUrl,
  onCloseClickedHandler: onCloseClickedHandler,
}: OfferModalProps) => {
  return (
    <Modal open={open}>
      <div className="flex items-center min-h-screen">
        {" "}
        <div
          className="rounded-lg p-6 text-center flex flex-col items-center"
          style={{
            position: "relative",
            // backgroundImage: `url(${imageUrl})`,
            // backgroundSize: "contain",
          }}
        >
          <img
            src="ftue_image.png"
            style={{ maxWidth: "88vw", maxHeight: "73vh" }}
          />
          <div className="px-4 pt-2 pb-4 space-y-2">
            <button
              className="w-full text-white  rounded-full font-bold"
              style={{
                backgroundColor: "#aaaaaaaa",
                width: "32px",
                height: "32px",
              }}
              onClick={onCloseClickedHandler}
            >
              X
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default OfferModal;
