import { Fragment, PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";
interface BackDropProps {
  show: boolean;
}
const Backdrop = ({
  show = false,
  children,
}: PropsWithChildren<BackDropProps>) => {
  if (!show) return null;
  return (
    <Transition
      as={Fragment}
      show={show}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      /** Do we remove this? */
      // style={{ overflow: "hidden" }}
    >
      {/* <div
        className={`fixed inset-0 z-50 bg-black bg-opacity-50${
          show ? "" : "hidden"
        }`}
      >
        <div className="spinner"></div>
      </div> */}
      <div
        className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center ${
          show ? "" : "hidden"
        }`}
      >
        {children}
      </div>
      {/* <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div
          className="relative bg-white"
          style={{ width: "80%", height: "40%", aspectRatio: "2 / 1" }}
        >
          <button
            className="absolute top-0 right-0 p-2 text-black bg-transparent hover:bg-gray-200"
            aria-label="Close"
          >
            ×
          </button>
        </div>
      </div> */}
    </Transition>
  );
};
export default Backdrop;
