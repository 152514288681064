import { create } from "zustand";
import { WebToApp } from "../constants/Constants";
import { AdClients, AdPlacement, AdUnitIds } from "../constants/AdConstants";
import { callWebToAppFunction } from "../utils/androidCallers";
import useAdUnitStore from "./useAdUnitStore";

type BannerAdState = {
  adLoadAttempt: number;
  maxLoadAttempts: number;
  isInsAdLoadedStatus: boolean;
  bnAdLoadFailedDueToOffline: boolean;
  incrementLoadAttempts: () => void;
  resetLoadAttempts: () => void;
  setBnAdFailedDueToOffline: (bnAdLoadFailedDueToOffline: boolean) => void;
  bnAdLoadRetryFunc: () => void;
  setInsAdLoaded: (loaded: boolean) => void;
  loadBNAd: () => void;
  retryLoadingBnAd: () => void;
  destroyBNAd: () => void;
};

const useBannerAdStore = create<BannerAdState>()((set, get) => ({
  adLoadAttempt: 0,
  maxLoadAttempts: 3,
  isInsAdLoadedStatus: false,
  bnAdLoadFailedDueToOffline: false,
  incrementLoadAttempts: () =>
    set((state) => ({ adLoadAttempt: state.adLoadAttempt + 1 })),
  resetLoadAttempts: () => set({ adLoadAttempt: 0 }),
  setBnAdFailedDueToOffline: (bnAdLoadFailedDueToOffline) =>
    set({ bnAdLoadFailedDueToOffline }),
  bnAdLoadRetryFunc: () => {
    set({ bnAdLoadFailedDueToOffline: false });
    get().loadBNAd();
  },
  setInsAdLoaded: (loaded) => set({ isInsAdLoadedStatus: loaded }),
  loadBNAd: () => {
    // console.log("calling load Banner ad inside useBannerAdStore");
    // const bnWidth = 384;
    const aspectRatio: number = 6.4;
    //const bnWidth = Math.floor(window.innerWidth * window.devicePixelRatio);
    //const bnHeight = Math.floor((bnWidth * 50) / 320);
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    // const vh = Math.floor(vw / aspectRatio);
    const vh = 0;
    // console.log("viewwidth for banner ad", vw, "viewheight for banner ads", vh);
    //console.log("bannerwidth", bnWidth, "banner height", bnHeight);
    const adUnit = useAdUnitStore
      .getState()
      .getAdUnitByPlacement(AdPlacement.platform_banner)!;
    callWebToAppFunction(
      WebToApp.LOAD_BN_AD,
      adUnit.adClient,
      adUnit.adUnitId,
      adUnit.placementName,
      "",
      {
        width: `${vw}`,
        height: `${vh}`, // hardcoded values for now
        x: "0",
        y: "0",
        hardwareAcceleration: false,
      },
    );
  },
  retryLoadingBnAd: () => {
    const { maxLoadAttempts, loadBNAd, incrementLoadAttempts } = get();
    if (get().adLoadAttempt < maxLoadAttempts) {
      console.log("Ins ad retry attempt:", get().adLoadAttempt);
      loadBNAd();
      incrementLoadAttempts();
    } else console.log("Ins ad retry has failed for banner ad:");
  },
  destroyBNAd: () => {
    // console.log("calling load Banner ad inside useBannerAdStore");
    const adUnit = useAdUnitStore
      .getState()
      .getAdUnitByPlacement(AdPlacement.platform_banner)!;
    callWebToAppFunction(
      WebToApp.DESTROY_BN,
      adUnit.adClient,
      adUnit.adUnitId,
      adUnit.placementName,
    );
  },
}));

export default useBannerAdStore;
