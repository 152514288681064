import { Outlet } from "react-router-dom";
import Navbar from "../pages/Navigation/Navbar";
export const RootLayout = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Navbar></Navbar>
        <Outlet></Outlet>
      </div>
    </>
  );
};
