export const AdClients = {
  AppLovin: "max",
};
export const AdUnitIds = {
  AppLovin: {
    platform_banner: "38d6611f98b0bbd5",
    game_start_fsi: "db074a698a5327a1",
    game_end_fsi: "33af6cf1298053c4",
    game_replay_fsi: "f79274acc2a530dd",
    game_exit_fsi: "863376655384ef6c",
  },
};
export const AdPlacement = {
  platform_banner: "platform_banner",
  game_start_fsi: "game_start_fsi",
  game_end_fsi: "game_end_fsi",
  game_replay_fsi: "game_replay_fsi",
  game_exit_fsi: "game_exit_fsi",
  game_play_fsr: "game_play_fsr",
  game_end_fsr: "game_end_fsr",
  game_pause_fsi: "game_pause_fsi",
};
export const AdFormat = {
  Banner: "Banner",
  Interstitial: "Interstitial",
  Rewarded: "Rewarded",
};
