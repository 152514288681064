import { Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import Backdrop from "../Backdrop";
interface ModalProps {
  open: boolean;
  children: ReactNode;
}
const Modal = ({ open, children }: ModalProps) => {
  return (
    <Fragment>
      <Backdrop show={open}></Backdrop>
      <Transition
        show={open}
        enter="transition ease-out duration-300 transform"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leave="transition ease-in duration-300 transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
        className="fixed inset-x-0 bottom-0 z-50 flex justify-center items-end"
      >
        <>{children}</>
      </Transition>
    </Fragment>
    // <Fragment>
    //   <Backdrop show={open}></Backdrop>
    //   <Transition
    //     show={open}
    //     enter="transition ease-out duration-300 transform"
    //     enterFrom="translate-y-full"
    //     enterTo="translate-y-0"
    //     leave="transition ease-in duration-300 transform"
    //     leaveFrom="translate-y-0"
    //     leaveTo="translate-y-full"
    //     className="fixed inset-x-0 bottom-0 z-50 flex justify-center items-end"
    //   >
    // <div className="w-full" style={{ height: "489px" }}>
    //   <div className="relative bg-white w-full h-full rounded-tl-3xl rounded-tr-3xl overflow-hidden">
    //     <div className="bg-[#FFF8E5] h-[113px] flex justify-center items-center relative overflow-clip hide-scroll-bar">
    //       <div className="bg-[#FFF7CE] w-36 h-28 rounded-full flex justify-center items-center mt-8">
    //         <VictoryCupFullIcon />
    //       </div>
    //     </div>
    //     <div className="text-center mt-2">
    //       <p className="font-bold text-xl">Victory</p>
    //     </div>
    //     <div className="px-4 pt-8 pb-4 relative">
    //       <div className="absolute top-[1px] left-0 right-0 bg-[#FFF8E5] rounded-full pt-4 pb-2 text-center border border-[green] mx-4">
    //         <p className="text-orange-500 font-bold">You won 10 credits</p>
    //       </div>
    //       <div className="bg-[#FFF8E5] pt-4 pb-4 px-4 text-center border border-[colorForLowerDiv] rounded-xl">
    //         <p>Your Game Score is: 14</p>
    //         <p>Get more game score to win more credits.</p>
    //       </div>
    //     </div>

    //     {/* Buttons */}
    //     <div className="px-4 pb-4 space-y-2">
    //       <button className="w-full bg-orange-500 text-white py-2 rounded-full font-bold">
    //         Replay
    //       </button>
    //       <button className="w-full text-orange-500 py-2 rounded-full font-bold border border-orange-500">
    //         Home
    //       </button>
    //     </div>
    //   </div>
    // </div>
    //   </Transition>
    // </Fragment>
  );
};

export default Modal;
