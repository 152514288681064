import React from "react";
import { IconProps } from "./interfaces/IconProps";
const LogoIcon: React.FC<IconProps> = ({ className }) => {
  return (
    <>
      <svg
        width="124"
        height="19"
        viewBox="0 0 124 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M80.0509 6.75906C80.1418 6.45467 80.3208 6.18402 80.5654 5.98124C80.8099 5.77845 81.109 5.65261 81.425 5.61957C81.6805 5.58197 81.9402 5.58197 82.1958 5.61957V6.79257C81.6209 6.76327 81.0448 6.81975 80.4866 6.96014C80.958 7.52251 81.2082 8.23742 81.1904 8.971C81.165 9.41745 81.0506 9.85432 80.8538 10.2559C80.6571 10.6574 80.3819 11.0155 80.0446 11.3091C79.7073 11.6027 79.3147 11.8258 78.8898 11.9653C78.465 12.1048 78.0165 12.1578 77.5708 12.1213C77.0167 12.1314 76.4639 12.0637 75.9286 11.9203C75.694 12.1213 75.3589 12.3895 75.3589 12.7581C75.3589 13.1268 75.56 13.3614 76.4984 13.4284L79.0454 13.596C80.8552 13.596 81.9277 14.1657 81.9277 15.9085C81.9277 17.6512 79.6152 18.7237 77.3697 18.7237C74.0183 18.7237 73.415 17.5842 73.415 16.5452C73.4298 16.1011 73.574 15.6709 73.8301 15.3077C74.0861 14.9444 74.4428 14.664 74.8562 14.5009C74.5786 14.4215 74.3341 14.2547 74.159 14.0252C73.9839 13.7958 73.8875 13.5159 73.8842 13.2273C73.8842 12.4565 74.4205 12.0208 75.0572 11.5181C74.6318 11.2656 74.2844 10.9002 74.0537 10.4625C73.8231 10.0248 73.718 9.53177 73.7502 9.03803C73.7473 8.5651 73.8446 8.09692 74.0356 7.66426C74.2265 7.2316 74.5069 6.84426 74.8583 6.5277C75.2097 6.21115 75.6241 5.97254 76.0743 5.82758C76.5244 5.68261 77.0002 5.63457 77.4703 5.6866C77.9523 5.66915 78.4323 5.75593 78.8777 5.94102C79.3231 6.12611 79.7232 6.40514 80.0509 6.75906ZM78.8444 15.1041H75.8951C75.2248 15.5733 74.9232 15.942 74.9232 16.5452C74.9232 17.1485 75.5264 17.7853 77.5708 17.7853C79.6152 17.7853 80.319 17.3161 80.319 16.3777C80.319 15.4393 80.0509 15.2047 78.7773 15.1041H78.8444ZM77.5038 7.19475C76.2973 7.19475 75.2583 7.73097 75.2583 9.10506C75.2583 10.4791 75.9286 11.0154 77.4703 11.0154C79.0119 11.0154 79.6822 10.3116 79.6822 9.13858C79.6822 7.96557 78.7773 7.19475 77.4368 7.19475H77.5038Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M89.8036 13.2943C89.8036 13.7635 89.8036 13.9981 90.3398 13.9981H90.876V15.0035C90.4874 15.1828 90.064 15.2744 89.636 15.2716C89.4792 15.2951 89.3193 15.2866 89.1659 15.2466C89.0126 15.2065 88.8689 15.1358 88.7436 15.0387C88.6183 14.9416 88.514 14.8202 88.437 14.6816C88.3599 14.5431 88.3118 14.3904 88.2954 14.2327C87.916 14.5923 87.4686 14.8724 86.9794 15.0566C86.4901 15.2408 85.969 15.3253 85.4467 15.3051C85.1119 15.3554 84.7702 15.3305 84.4462 15.2322C84.1223 15.1339 83.8243 14.9647 83.574 14.7368C83.3237 14.5089 83.1272 14.2281 82.999 13.9148C82.8707 13.6016 82.8139 13.2636 82.8326 12.9256C82.8326 10.5796 84.9105 9.90934 87.424 9.90934H88.1949V9.07148C88.1949 7.93199 87.8932 7.26171 86.4186 7.26171C84.944 7.26171 84.7429 7.73091 84.5753 8.73634L83.4023 8.36768C83.6704 6.72548 84.7429 5.95465 86.7538 5.95465C88.7646 5.95465 89.9041 6.79251 89.9041 8.97094L89.8036 13.2943ZM88.2619 10.9483H87.3905C86.1505 10.9483 84.5418 10.9483 84.5418 12.557C84.5418 13.3278 84.8099 13.9646 86.0164 13.9646C86.8607 13.9352 87.6626 13.588 88.2619 12.9927V10.9483Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M94.261 14.97H92.6858V6.12222H94.0264V7.12765C94.8668 6.40114 95.9322 5.98683 97.0427 5.95465C98.2157 5.95465 98.9195 6.32331 99.2546 7.16117C100.093 6.40521 101.176 5.97685 102.304 5.95465C104.148 5.95465 104.818 6.96008 104.818 8.93742V14.97H103.511V9.17203C103.511 7.99902 103.276 7.32874 102.137 7.32874C101.264 7.40605 100.435 7.74476 99.7573 8.30065C99.774 8.51258 99.774 8.72549 99.7573 8.93742V14.97H98.0816V9.17203C98.0816 7.99902 97.847 7.32874 96.7075 7.32874C95.8014 7.40267 94.9434 7.76704 94.261 8.36768V14.97Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M107.03 10.5797C106.94 9.99183 106.982 9.39137 107.152 8.82155C107.322 8.25174 107.616 7.72675 108.014 7.28441C108.411 6.84207 108.902 6.49337 109.45 6.26345C109.999 6.03352 110.591 5.92808 111.186 5.95472C113.632 5.95472 114.537 7.56341 114.537 9.74184C114.568 10.1322 114.568 10.5245 114.537 10.9148H108.672C108.672 13.1603 109.744 13.8641 111.152 13.8641C111.635 13.922 112.122 13.7901 112.509 13.4963C112.897 13.2024 113.155 12.7696 113.23 12.2889L114.604 12.6241C114.415 13.3973 113.958 14.0783 113.313 14.5449C112.668 15.0116 111.878 15.2336 111.085 15.1712C107.968 15.1712 107.03 12.9927 107.03 10.5797ZM113.062 9.67481C113.062 8.09964 112.459 7.22826 111.085 7.22826C110.763 7.21875 110.442 7.27578 110.143 7.39581C109.843 7.51583 109.572 7.69627 109.345 7.92585C109.119 8.15543 108.942 8.42923 108.826 8.73015C108.711 9.03107 108.658 9.35265 108.672 9.67481H113.062Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M123.016 8.50184L121.609 8.80347C121.374 7.79804 121.039 7.19479 119.765 7.19479C118.492 7.19479 118.056 7.73102 118.056 8.4013C118.056 9.07159 118.793 9.5743 120 9.84242C121.206 10.1105 123.15 10.5127 123.15 12.49C123.15 14.4674 121.843 15.1712 119.799 15.1712C117.754 15.1712 116.447 14.3668 116.28 12.6241L117.687 12.2219C117.712 12.4798 117.789 12.7299 117.913 12.9572C118.037 13.1844 118.206 13.384 118.41 13.5439C118.614 13.7038 118.848 13.8205 119.098 13.8871C119.348 13.9537 119.61 13.9687 119.866 13.9312C120.938 13.9312 121.709 13.596 121.709 12.6576C121.709 11.7192 121.005 11.5181 119.799 11.2835C118.592 11.0489 116.615 10.6803 116.615 8.60239C116.615 6.5245 118.09 5.95476 119.966 5.95476C120.706 5.89843 121.439 6.13308 122.009 6.60878C122.578 7.08448 122.94 7.76368 123.016 8.50184Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M3.50936 17.7574H0L3.76671 0H7.27607L3.50936 17.7574Z"
          fill="#F86A1A"
        />
        <path
          d="M16.7044 17.7573H13.4056L14.9263 10.7386C14.959 10.4979 14.959 10.254 14.9263 10.0133C14.9419 9.76906 14.9063 9.52423 14.8217 9.29456C14.7371 9.0649 14.6053 8.85548 14.435 8.67975C14.2462 8.50743 14.0246 8.37507 13.7833 8.29064C13.5421 8.20621 13.2863 8.17144 13.0312 8.18844C12.7144 8.17781 12.3991 8.23731 12.1079 8.36269C11.8167 8.48807 11.5568 8.67622 11.3467 8.91371C10.8795 9.42578 10.5636 10.0575 10.4343 10.7386L8.96038 17.7573H5.63818L7.79059 7.53336C7.94865 6.84223 8.0349 6.13661 8.04794 5.42775H11.2766C11.305 5.84839 11.305 6.27048 11.2766 6.69112C11.6729 6.15571 12.1982 5.72914 12.8035 5.45102C13.4089 5.17289 14.0747 5.05222 14.7391 5.10021C15.2561 5.07578 15.7724 5.15881 16.2557 5.34405C16.7389 5.52928 17.1785 5.8127 17.5466 6.17641C18.2207 6.9524 18.5723 7.95692 18.5292 8.9839C18.5276 9.42428 18.4805 9.86332 18.3889 10.2941L16.7044 17.7573Z"
          fill="#F86A1A"
        />
        <path
          d="M18.085 14.0374L20.8924 13.4759C20.8798 13.5534 20.8798 13.6324 20.8924 13.7099C20.8885 14.215 21.0815 14.7017 21.4305 15.0668C21.6162 15.2547 21.839 15.4017 22.0848 15.4984C22.3305 15.5951 22.5938 15.6394 22.8577 15.6283C23.0604 15.6442 23.2642 15.6192 23.4571 15.5549C23.65 15.4906 23.828 15.3883 23.9807 15.254C24.1064 15.1477 24.2072 15.015 24.2759 14.8655C24.3446 14.7159 24.3796 14.553 24.3784 14.3884C24.3654 14.117 24.2683 13.8564 24.1007 13.6426C23.933 13.4289 23.703 13.2725 23.4426 13.1952L22.0622 12.5869C21.3117 12.3718 20.6498 11.9217 20.174 11.3027C19.6981 10.6837 19.4334 9.92837 19.4185 9.14773C19.4169 8.60202 19.5331 8.0624 19.7592 7.56574C19.9853 7.06909 20.316 6.62712 20.7287 6.27005C21.1856 5.85309 21.7207 5.53088 22.303 5.32206C22.8853 5.11323 23.5033 5.02195 24.1211 5.05348C24.9174 5.03234 25.7107 5.15927 26.4606 5.42781C27.0175 5.6299 27.5103 5.97723 27.8878 6.43382C28.1706 6.80507 28.399 7.21471 28.5662 7.6504C28.7003 8.03505 28.7791 8.43679 28.8002 8.84358L25.8289 9.35829C25.8368 8.91572 25.6876 8.48468 25.4078 8.14171C25.234 7.95274 25.0192 7.80602 24.78 7.71276C24.5407 7.6195 24.2833 7.58216 24.0275 7.60361C23.6381 7.58685 23.2559 7.7115 22.9513 7.95454C22.825 8.06546 22.7241 8.20229 22.6555 8.3557C22.5869 8.50912 22.5521 8.67552 22.5535 8.84358C22.5565 9.07764 22.6324 9.30494 22.7709 9.49371C22.9093 9.68247 23.1032 9.82326 23.3256 9.89639L24.8931 10.5515C25.5385 10.7399 26.1222 11.0965 26.5843 11.5849C27.0464 12.0733 27.3702 12.6758 27.5226 13.3307C27.675 13.9855 27.6505 14.6691 27.4515 15.3113C27.2526 15.9535 26.8864 16.5313 26.3904 16.9853C25.915 17.4101 25.3601 17.7364 24.7577 17.9452C24.1553 18.1541 23.5174 18.2413 22.8811 18.2019C22.0352 18.2043 21.1983 18.029 20.4245 17.6872C19.8282 17.4636 19.3141 17.0637 18.9506 16.5408C18.6604 16.1319 18.4318 15.6825 18.2721 15.2072C18.1576 14.8274 18.0946 14.434 18.085 14.0374Z"
          fill="#F86A1A"
        />
        <path
          d="M32.637 17.8977C31.7185 17.9477 30.8152 17.6466 30.1103 17.0555C29.7992 16.7539 29.5566 16.3888 29.3992 15.9851C29.2418 15.5814 29.1731 15.1485 29.1979 14.7159C29.1621 14.3972 29.1621 14.0754 29.1979 13.7567L30.3443 8.39908H28.1685L28.8001 5.42783H29.2915C29.8557 5.43421 30.4032 5.23646 30.8331 4.87102C31.263 4.50558 31.5464 3.99707 31.631 3.43919L32.1223 1.7547H35.1638L34.4619 5.42783H36.8015L36.1698 8.39908H33.8302L32.754 13.5227C32.7313 13.6857 32.7313 13.8511 32.754 14.0141C32.754 14.7159 33.1985 15.0669 34.0876 15.0669C34.3749 15.1038 34.6659 15.1038 34.9532 15.0669L34.2279 17.6404C33.7172 17.8204 33.1784 17.9076 32.637 17.8977Z"
          fill="#F86A1A"
        />
        <path
          d="M45.6217 5.42782H48.8503L46.8149 15.0435C46.6605 15.8138 46.5822 16.5974 46.581 17.383C46.5695 17.5388 46.5695 17.6952 46.581 17.8509H43.5395C43.5252 17.6562 43.5252 17.4608 43.5395 17.2661C43.5395 17.0555 43.5395 16.7513 43.5395 16.3536C43.1836 16.9002 42.6935 17.3464 42.1159 17.6495C41.5384 17.9526 40.8927 18.1025 40.2407 18.0849C39.5753 18.0805 38.9197 17.9244 38.3237 17.6284C37.7278 17.3325 37.2072 16.9045 36.8015 16.377C35.9111 15.2644 35.4466 13.8708 35.4914 12.4465C35.4337 10.5386 36.0742 8.67511 37.2928 7.20589C37.8326 6.5353 38.517 5.99546 39.2949 5.62677C40.0728 5.25808 40.924 5.0701 41.7848 5.07688C42.5982 5.04974 43.4046 5.23521 44.1244 5.61499C44.6243 5.88485 45.0379 6.29032 45.3176 6.78477L45.6217 5.42782ZM42.0188 8.11833C41.5514 8.11523 41.091 8.23185 40.6815 8.4571C40.2719 8.68235 39.9269 9.00871 39.6792 9.40509C39.0925 10.2844 38.7829 11.3193 38.7902 12.3763C38.7534 13.0977 38.987 13.8068 39.4453 14.365C39.6708 14.6018 39.9435 14.7887 40.2457 14.9136C40.5479 15.0385 40.873 15.0987 41.1999 15.0903C41.6559 15.1018 42.1077 15.0012 42.5157 14.7972C42.9236 14.5932 43.2752 14.2922 43.5395 13.9205C44.2073 12.9987 44.5377 11.8752 44.4753 10.7386C44.5082 10.0392 44.274 9.35347 43.8203 8.8202C43.5967 8.56934 43.3169 8.37497 43.0038 8.25298C42.6907 8.13099 42.3531 8.08485 42.0188 8.11833Z"
          fill="#F86A1A"
        />
        <path
          d="M59.0974 17.7574H55.7518L57.2959 10.7386C57.3179 10.4974 57.3179 10.2546 57.2959 10.0134C57.3116 9.76911 57.2759 9.52429 57.1913 9.29462C57.1067 9.06496 56.975 8.85553 56.8046 8.67981C56.6158 8.50749 56.3942 8.37513 56.1529 8.2907C55.9117 8.20626 55.6559 8.1715 55.4009 8.1885C55.084 8.17787 54.7687 8.23737 54.4775 8.36275C54.1863 8.48812 53.9264 8.67628 53.7164 8.91377C53.2491 9.42584 52.9332 10.0576 52.8039 10.7386L51.2598 17.8743H48.0078L50.1602 7.6504C50.3297 6.96131 50.4161 6.25441 50.4176 5.54478H53.6462C53.6746 5.96543 53.6746 6.38751 53.6462 6.80815C54.0426 6.27275 54.5678 5.84618 55.1732 5.56805C55.7785 5.28992 56.4443 5.16925 57.1087 5.21724C57.6257 5.19282 58.1421 5.27585 58.6253 5.46108C59.1085 5.64632 59.5481 5.92974 59.9162 6.29345C60.5967 7.06712 60.9565 8.07117 60.9222 9.10093C60.9124 9.54209 60.8575 9.98109 60.7585 10.4111L59.0974 17.7574Z"
          fill="#F86A1A"
        />
        <path
          d="M65.3208 17.8977C64.4016 17.9525 63.4966 17.6508 62.7941 17.0555C62.4787 16.756 62.2318 16.3919 62.0703 15.9881C61.9088 15.5842 61.8364 15.1503 61.8582 14.7159C61.8611 14.3928 61.9003 14.071 61.9752 13.7567L63.1216 8.39908H60.9458L61.5775 5.42783H62.0688C62.633 5.43421 63.1805 5.23646 63.6105 4.87102C64.0404 4.50558 64.3238 3.99707 64.4084 3.43919L64.8763 1.7547H67.8709L67.0755 5.42783H69.4151L68.7834 8.39908H66.4438L65.3442 13.5227C65.3215 13.6857 65.3215 13.8511 65.3442 14.0141C65.3442 14.7159 65.7887 15.0669 66.6778 15.0669C66.9651 15.1038 67.256 15.1038 67.5434 15.0669L66.9819 17.734C66.4431 17.8908 65.8799 17.9463 65.3208 17.8977Z"
          fill="#F86A1A"
        />
      </svg>
    </>
  );
};
export default LogoIcon;
