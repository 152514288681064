import { BackIcon } from "../../components/icons";
import { useNavigate, useLocation } from "react-router-dom";
const MobileViewNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let navtitle = "Settings";
  const pathname = location.pathname;
  switch (pathname) {
    case "/feedback":
      navtitle = "Feedback";
      break;
    case "/terms-of-service":
      navtitle = "Terms of Service";
      break;
    case "/testing":
      navtitle = "Testing";
      break;
    case "/healthcheck":
      navtitle = "Healthcheck";
      break;
    default:
      navtitle = "Settings";
      break;
  }
  return (
    <nav className="bg-white p-2 fixed w-full border-b border-gray-200">
      <div className="px-2 py-2 bg-white">
        <button
          onClick={() => {
            if (navtitle !== "Settings") navigate("/settings");
            else navigate("/");
          }}
          className="text-black font-semibold flex items-center"
        >
          <BackIcon />
          <span className="ml-2 font-[500] text-black text-[18px] leading-[21.6px]">
            {navtitle}
          </span>
        </button>
      </div>
      {/* <div
        className={`mx-2 mt-2 rounded-md overflow-auto max-h-screen ${
          isMobileMenuOptionSelected ? "" : "hidden"
        }`}
      >
        <div className="px-4 py-4 z-20 border-b border-gray-200 bg-white">
          <span className="font-[600] text-black text-[16px] leading-[19.2px]">
            {itemScreenHeading}
          </span>
          <p className="font-[400] text-black text-[14px] leading-[16.8px]">
            {itemScreenContent}
          </p>
        </div>
      </div> */}
      {/* </div> */}
    </nav>
  );
};
export default MobileViewNavbar;
