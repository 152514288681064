import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { GamePopups, Pages } from "../constants/PageConstants";

type PageState = {
  page: Pages;
  popup: GamePopups;
  setPage: (page: Pages) => void;
  setPopup: (popup: GamePopups) => void;
};

const usePageStore = create<PageState>()((set, get) => ({
  page: Pages.HOME,
  popup: GamePopups.NONE,
  setPage: (page) => set({ page }),
  setPopup: (popup) => set({ popup }),
}));

export default usePageStore;
