import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { Game } from "../types/Game";

type GameState = {
  isGamesFetched: boolean;
  games: {
    [key: string]: Game[];
  };
  categoryRanking: string[];
  setGames: (games: { [key: string]: Game[] }) => void;
  setCategoryRanking: (ranking: string[]) => void;
};

const useGameStore = create<GameState>()(
  persist(
    (set, get) => ({
      isGamesFetched: false,
      games: {},
      /** this was added not to break existing flow might have to remove this later */
      categoryRanking: [],
      setGames: (games) => set({ isGamesFetched: true, games }),
      setCategoryRanking: (ranking) =>
        set({ isGamesFetched: true, categoryRanking: ranking }),
      // setGamesByRank: (gamesByRank1) => set({ gamesByRank: gamesByRank1 }),
    }),
    {
      name: "games-storage",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useGameStore;
