import { MutableRefObject, useEffect } from "react";
import { ErrorIcon } from "../../icons";
import Modal from "../Modal";
import { Game } from "../../../types/Game";
import { EventInfo } from "../../../types/window";
import {
  EventTypes,
  PopupActions,
  PopupType,
  WebToApp,
} from "../../../constants/Constants";
import { callWebToAppFunction } from "../../../utils/androidCallers";
import * as Sentry from "@sentry/browser";
interface OfflineModalProps {
  open: boolean;
  onRetryClickedHandler: () => void;
  referrerflow?: MutableRefObject<string>;
  exitShow?: boolean;
  game?: Game | undefined;
  sessionId?: string;
}
const ErrorModal = ({
  onRetryClickedHandler,
  open,
  exitShow = false,
  referrerflow,
  game,
  sessionId,
}: OfflineModalProps) => {
  useEffect(() => {
    Sentry.captureMessage("UNEXPECTED_ERROR popup displayed");
  }, []);

  return (
    <Modal open={open}>
      <div className="flex items-center min-h-screen">
        {" "}
        {/* Added padding-top to give space for navbar */}
        <div className="bg-white rounded-lg p-6 text-center flex flex-col items-center">
          {/* SVG icon centered */}
          <div className="flex justify-center">
            <ErrorIcon />
          </div>
          <h2 className="mt-4 text-lg font-semibold">Something went wrong</h2>
          <p className="mt-2 text-gray-600">
            We have encountered an unexpected error. Kindly retry loading this
            page. Sorry for the inconvinience.
          </p>
          <button
            onClick={() => {
              const modalActionEvent: EventInfo = {
                eventType: EventTypes.modal_action,
                eventProperties: {
                  web_timestamp: Date.now(),
                  internetstate: window.navigator.onLine ? "online" : "offline",
                  popup_type: PopupType.UNEXPECTED_ERROR,
                  game_name: game?.name,
                  game_id: game?.id,
                  referrerflow: referrerflow?.current,
                  game_session_id: sessionId,
                  action: PopupActions.RETRY,
                },
              };
              callWebToAppFunction(
                WebToApp.ANALYTICS_LISTENER,
                "",
                "",
                "",
                modalActionEvent,
                null,
                undefined,
              );
              onRetryClickedHandler();
            }}
            className="w-full mt-4 text-[#4A4A4A] py-2 rounded-full border-[1px] border-[#F86A1B] flex justify-center items-center transition transform active:scale-95 active:bg-[#F86A1B]/20"
          >
            <span className="font-[700] text-[#F86A1B] text-[14px] leading-[16.8px] tracking-[1px] uppercase">
              Try Again
            </span>
          </button>
          {exitShow && (
            <button
              onClick={() => {
                const modalActionEvent: EventInfo = {
                  eventType: EventTypes.modal_action,
                  eventProperties: {
                    web_timestamp: Date.now(),
                    internetstate: window.navigator.onLine
                      ? "online"
                      : "offline",
                    popup_type: PopupType.UNEXPECTED_ERROR,
                    game_name: game?.name,
                    game_id: game?.id,
                    referrerflow: referrerflow?.current,
                    game_session_id: sessionId,
                    action: PopupActions.EXIT,
                  },
                };
                callWebToAppFunction(
                  WebToApp.ANALYTICS_LISTENER,
                  "",
                  "",
                  "",
                  modalActionEvent,
                  null,
                  undefined,
                );
                history.back();
              }}
              className="w-full mt-4 text-[#4A4A4A] py-2 rounded-full border-[#F86A1B] flex justify-center items-center transition transform active:scale-95 active:bg-[#F86A1B]/20"
            >
              <span className="font-[700] text-[#F86A1B] text-[14px] leading-[16.8px] tracking-[1px] uppercase">
                Exit
              </span>
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default ErrorModal;
